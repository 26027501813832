import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

import reportWebVitals from './app/reportWebVitals';
import App from './app/App';
import { getLocale } from '@explorer-locales';

// Set locale
const locale = getLocale();
if (document.documentElement) {
    document.documentElement.setAttribute("lang", locale.locale);
}

ReactDOM.render(
    <IntlProvider locale={locale.locale} messages={locale.app} defaultLocale="en-US">
        <ConfigProvider locale={locale.ant}>
            <App />
        </ConfigProvider>
    </IntlProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
