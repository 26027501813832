import React from 'react';
import {
    message,
    Card,
    Skeleton,
    Space,
} from 'antd';
import {
    BarChartOutlined,
    CodeSandboxOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Blockchain, Common } from '@explorer-components';
import { formatCapacity } from '@explorer-utils/format';
import { doRequest } from '@explorer-utils/request';

import './index.less';

const Dashboard = () => {
    const [network, setNetwork] = React.useState(null);
    const [blockAge, setBlockAge] = React.useState(0);

    React.useEffect(() => {
        const updateNetwork = () => {
            return doRequest.get({
                url: '/api/blockchain/network'
            }).then(result => {
                setNetwork(result.data);
            }).catch(({ code, error }) => {
                message.error(error + " (" + code + ")");
            });
        };

        updateNetwork();

        let updateNetworkTimerId = setInterval(() => updateNetwork(), 5 * 60 * 1000);
        return () =>  {
            clearInterval(updateNetworkTimerId);
        }
    }, []);
    React.useEffect(() => {
        let updateBlockAgeTimerId = setInterval(() => network && setBlockAge(Math.max(new Date().getTime() / 1000 - network.best.time, 0)), 1000);
        return () =>  {
            clearInterval(updateBlockAgeTimerId);
        }
    }, [network])

    const NetworkTitle = () => (
        <Space>
            <CodeSandboxOutlined />
            <FormattedMessage id="dashboard.network.title" />
        </Space>
    );
    const CapacityTitle = () => (
        <Space>
            <BarChartOutlined />
            <FormattedMessage id="dashboard.capacity.title" />
        </Space>
    );
    const BlocksTitle = () => (
        <Space>
            <UnorderedListOutlined />
            <FormattedMessage id="dashboard.blocks.title" />
        </Space>
    );
    return (
        <div className="explorer-dashboard">
            <Card
                className="explorer-dashboard-network"
                title={<NetworkTitle />}
            >
                <Skeleton loading={!network}>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.name" /></span>
                        <span className="network-box-content">{network ? network.name : ''}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.height" /></span>
                        <span className="network-box-content">{network ? network.best.height : ''}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.blockAge" /></span>
                        <span className="network-box-content">{<Common.DurtationLabel durtation={blockAge} visibility={{ day: false, hour: false }} />}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.capacity" /></span>
                        <span className="network-box-content">{network ? formatCapacity(network.capacity) : ''}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.dayRewardRate" /></span>
                        <span className="network-box-content">{network && network.capacity > 0 ? new Number(480 * parseFloat(network.block_reward) / network.capacity * 1024).toFixed(2) : ''} QTC/PiB</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.blockReward" /></span>
                        <span className="network-box-content">{network ? new Number(network.block_reward).toFixed(2) : ''} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.pledgeRatio" /></span>
                        <span className="network-box-content">{network ? new Number(network.ratio).toFixed(2) : ''} QTC/TiB</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.users" /></span>
                        <span className="network-box-content">{network ? network.holder_count : ''}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.nextHalvingBlocks" /></span>
                        <span className="network-box-content">{network ? network.next_halving_blocks : ''}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.nextHalvingTime" /></span>
                        <span className="network-box-content">{network ? <Common.DurtationLabel durtation={network.next_halving_time} visibility={{ minute: false, second: false }} /> : ''}</span>
                    </div>
                </Skeleton>
            </Card>
            <Card
                className="explorer-dashboard-capacity"
                title={<CapacityTitle />}
            >
                <Blockchain.CapacityChart />
            </Card>
            <Card
                className="explorer-dashboard-blocks"
                title={<BlocksTitle />}
            >
                <Blockchain.BlockList count={10} pagination={false} />
            </Card>
        </div >
    );
};

export default Dashboard;