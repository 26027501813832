export default {
    // lang
    "lang.en-US": "English",
    "lang.zh-CN": "简体中文",
    // components
    "components.timer.seconds": "秒",
    "components.timer.minutes": "分",
    "components.timer.hours": "小时",
    "components.timer.days": "天",
    // error
    "error.gotohome": "回到主页",
    "error.message404": "找不到匹配的资源",
    // app
    "app.copyright": "Copyright © 2022 qitcoin.org. 保留所有权利。",
    // header
    "header.homepage": "首页",
    "header.blockchain.blocks": "区块",
    "header.blockchain.transactions": "交易",
    "header.blockchain.capacity": "容量排行",
    "header.blockchain.accounts": "富豪榜",
    "header.blockchain.network.label": " ",
    "header.blockchain.network.mainnet": " ",
    "header.search.placeholder": "搜索 区块高度/区块哈希/交易哈希/地址/算力表示",
    // footer
    "footer.link.org": "Qitcoin 官网",
    "footer.wallet.sourcecode": "Qitcoin 源代码",
    "footer.wallet.download": "下载 Qitcoin 钱包",
    "footer.link.coinmarketcap":"CoinMarketCap (CMC)",
    "footer.link.feixiaohao":"非小号",
    "footer.link.coinw":"币赢",
    "footer.link.coincarp":"CoinCarp",
    "footer.link.balletcrypto":"Ballet Wallet",
    // dashboard
    "dashboard.network.title": "全网概览",
    "dashboard.network.name": "当前网络",
    "dashboard.network.height": "区块高度",
    "dashboard.network.blockAge": "最新区块年龄",
    "dashboard.network.capacity": "全网难度",
    "dashboard.network.dayRewardRate": "日产出率",
    "dashboard.network.blockReward": "区块奖励",
    "dashboard.network.pledgeRatio": "质押率",
    "dashboard.network.users": "独立地址数",
    "dashboard.network.nextHalvingBlocks": "减半剩余区块",
    "dashboard.network.nextHalvingTime": "减半剩余时间",
    "dashboard.capacity.title": "算力走势",
    "dashboard.blocks.title": "最近区块",
    // capacity
    "capacity.range.week": "周",
    "capacity.range.month": "月",
    "capacity.range.year": "年",
    "capacity.range.quarter": "季",
    "capacity.range.all": "所有",
    // block list
    "blocklist.height": "高度",
    "blocklist.time": "出块时间",
    "blocklist.tx": "交易数",
    "blocklist.generator": "出块地址",
    // block view
    "blockview.title": "区块信息",
    "blockview.previous": "上一个",
    "blockview.next": "下一个",
    "blockview.height": "区块高度",
    "blockview.hash": "区块哈希",
    "blockview.time": "区块时间",
    "blockview.generator": "出块者",
    "blockview.plotter": "算力标识",
    "blockview.nonce": "答案",
    "blockview.deadline": "答案质量",
    "blockview.difficulty": "难度",
    "blockview.reward": "产出总额",
    "blockview.txs": "打包的交易",
    // tx item
    "txitem.time": "交易时间",
    "txitem.from": "发送地址",
    "txitem.to": "接收地址",
    "txitem.amount": "交易总额",
    "txitem.fee": "交易费用",
    "txitem.size": "交易尺寸",
    "txitem.coinbase": "出块奖励",
    "txitem.blockhash": "区块哈希",
    "txitem.vout.MinedReward": "出块奖励 (这是即时发放的部分,还有部分会线性释放)",
    "txitem.vout.BindPlotter": "绑定算力",
    "txitem.vout.Point": "抵押指向",
    "txitem.vout.Staking": "权益授权",
    "txitem.vout.receiver": "接收地址: ",
    "txitem.vout.amount": "有效金额: ",
    "txitem.vout.lockBlocks": "锁定高度: ",
    // address view
    "addressview.title": "地址信息",
    "addressview.address": "地址",
    "addressview.amount": "总金额",
    "addressview.viewcoins": "查看明细",
    "addressview.spendable": "可用总额",
    "addressview.txn": "交易总数",
    "addressview.stakingfrom": "收到的权益",
    "addressview.stakingto": "发出的权益",
    "addressview.pointfrom": "收到的质押",
    "addressview.pointto": "发出的质押",
    "addressview.miningblock": "周产出区块数",
    "addressview.miningblock.percent": "占比",
    "addressview.bindcount": "绑定的算力数",
    "addressview.capacity": "评估的算力",
    "addressview.miningrequire": "需要的质押",
    "addressview.txs": "相关交易",
    // plotter list
    "plotterlist.title": "算力列表",
    "plotterlist.address": "绑定到的地址",
    "plotterlist.total": "绑定数量",
    "plotterlist.plotter": "算力标识",
    "plotterlist.bindTx": "绑定交易",
    // plotter view
    "plotterview.title": "算力信息",
    "plotterview.plotter": "算力标识",
    "plotterview.capacity": "评估的算力",
    "plotterview.miningblock": "周产出区块数",
    "plotterview.address": "绑定到的地址",
    "plotterview.txid": "发起绑定的交易",
    "plotterview.bindheightlimit": "绑定切换限制",
    "plotterview.unbindheightlimit": "解除绑定限制",
    "plotterview.notbind": "尚未绑定",
    "plotterview.blocks": "相关区块",
    "plotterview.blocks.height": "高度",
    "plotterview.blocks.generator": "地址",
    // accounts
    "accounts.type.account": "总金额",
    "accounts.type.point": "指向金额",
    "accounts.type.staking": "权益金额",
    "accounts.NO": "排位",
    "accounts.address": "地址",
    "accounts.amount": "金额",
    "accounts.summary.amount": "总金额",
    "accounts.summary.total": "总数",
    // coin list
    "coinlist.title": "零钱明细",
    "coinlist.address": "地址",
    "coinlist.total": "零钱总数",
    "coinlist.txid": "交易",
    "coinlist.amount": "金额",
    "coinlist.type": "类型",
    "coinlist.type.Coinbase": "出块奖励",
    "coinlist.type.Pay": "支付",
    "coinlist.type.BindPlotter": "绑定算力",
    "coinlist.type.Point": "指向",
    "coinlist.type.Staking": "权益",
}