import React, { Fragment } from 'react';
import { message } from 'antd';
import {
    CopyOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import { eclipseHash } from '@explorer-utils/format';

import './LinkLabel.less'

const LinkLabel = ({ className = "", value = "", type = "", compact = false, copyable = true }) => {
    const [copied, setCopied] = React.useState(false);
    const doCopy = (text) => {
        try {
            navigator.clipboard.writeText(text);
            setCopied(true);
        } catch (e) {
            message.error(e);
        }
    }

    return (
        <span className={`explorer-linkable explorer-${type} ${className}`}>
            <a href={`/blockchain/${type}/${value}`}>{compact ? eclipseHash(value) : value}</a>
            {copyable && (
                <Fragment>
                    {!copied && (
                        <CopyOutlined className="explorer-linkable-btn explorer-linkable-copy" onClick={() => doCopy(value)} />
                    )}
                    {copied && (
                        <CheckOutlined className="explorer-linkable-btn explorer-linkable-copied" onClick={() => doCopy(value)} />
                    )}
                </Fragment>
            )}
        </span>
    )
};

export default LinkLabel;