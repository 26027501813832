import React from 'react';
import {
    message,
    List,
    Pagination,
} from 'antd';
import { Blockchain } from "@explorer-components";
import { doRequest } from '@explorer-utils/request';

import './index.less';

const TransactionsPage = () => {
    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        setLoading(true);
        doRequest.get({
            url: "/api/blockchain/transactions",
            data: {
                page: page - 1,
                count: countOfPage,
            }
        }).then(({ data }) => {
            setTransactions(data.data);
            setTotal(data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [page]);

    return (
        <div className="explorer-transactions">
            <List
                className="explorer-transactions-list"
                loading={loading}
                dataSource={transactions}
                renderItem={(item) => <Blockchain.TransactionItem data={item} compact={true} />}
            />
            <Pagination
                className="explorer-pagination"
                current={page}
                total={total}
                pageSize={countOfPage}
                onChange={(v) => setPage(v)}
                showSizeChanger={false}
            />
        </div>
    )
};

export default TransactionsPage;