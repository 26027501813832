import React from 'react';
import { Blockchain } from '@explorer-components';

import './index.less';

const BlocksPage = () => {
    return (
        <div className="explorer-blocks">
            <Blockchain.BlockList count={20} pagination={true} />
        </div>
    );
};

export default BlocksPage;