export default {
    // lang
    "lang.en-US": "English",
    "lang.zh-CN": "简体中文",
    // components
    "components.timer.seconds": "s",
    "components.timer.minutes": "m",
    "components.timer.hours": "h",
    "components.timer.days": "d",
    // error
    "error.gotohome": "Back to Home",
    "error.message404": "Not Found",
    // app
    "app.copyright": "Copyright © 2022 qitcoin.org.",
    // header
    "header.homepage": "Home",
    "header.blockchain.blocks": "Blocks",
    "header.blockchain.transactions": "Transactions",
    "header.blockchain.capacity": "Capacity",
    "header.blockchain.accounts": "Rich List",
    "header.blockchain.network.label": " ",
    "header.blockchain.network.mainnet": " ",
    "header.search.placeholder": "Search by Height/Block Hash/Transaction Hash/Address/Plotter ID",
    // footer
    "footer.link.org": "Qitcoin Official",
    "footer.wallet.sourcecode": "Qitcoin Source Code",
    "footer.wallet.download": "Download Qitcoin Wallet",
    "footer.link.coinmarketcap":"CoinMarketCap (CMC)",
    "footer.link.feixiaohao":"FeiXiaoHao",
    "footer.link.coinw":"CoinW",
    "footer.link.coincarp":"CoinCarp",
    "footer.link.balletcrypto":"Ballet Wallet",
    // dashboard
    "dashboard.network.title": "Network Overview",
    "dashboard.network.name": "Current Network",
    "dashboard.network.height": "Block Height",
    "dashboard.network.blockAge": "Latest Block",
    "dashboard.network.capacity": "Network Difficulty",
    "dashboard.network.dayRewardRate": "Day Mined",
    "dashboard.network.blockReward": "Block Reward",
    "dashboard.network.pledgeRatio": "Pledge Ratio",
    "dashboard.network.users": "Address",
    "dashboard.network.nextHalvingBlocks": "Next Halving Blocks",
    "dashboard.network.nextHalvingTime": "Next Halving Time",
    "dashboard.capacity.title": "Difficulty Chart",
    "dashboard.blocks.title": "Latest Blocks",
    // capacity
    "capacity.range.week": "Week",
    "capacity.range.month": "Month",
    "capacity.range.quarter": "Quarter",
    "capacity.range.year": "Year",
    "capacity.range.all": "All",
    // block list
    "blocklist.height": "Height",
    "blocklist.time": "Time",
    "blocklist.tx": "Transactions",
    "blocklist.generator": "Generator",
    // block view
    "blockview.title": "Block Summary",
    "blockview.previous": "Previous",
    "blockview.next": "Next",
    "blockview.height": "Height",
    "blockview.hash": "Hash",
    "blockview.time": "Time",
    "blockview.generator": "Generator",
    "blockview.plotter": "Plotter",
    "blockview.nonce": "Nonce",
    "blockview.deadline": "Deadlin",
    "blockview.difficulty": "Difficulty",
    "blockview.reward": "Reward",
    "blockview.txs": "Block Transactions",
    // tx item
    "txitem.time": "Time",
    "txitem.from": "From Address",
    "txitem.to": "To Address",
    "txitem.amount": "Amount",
    "txitem.fee": "Fee",
    "txitem.size": "Size",
    "txitem.coinbase": "Coinbase",
    "txitem.blockhash": "Block Hash",
    "txitem.vout.MinedReward": "Coinbase (This is the immediate release part, and the other part will be released linearly)",
    "txitem.vout.BindPlotter": "Bind Plotter",
    "txitem.vout.Point": "Pledge Point",
    "txitem.vout.Staking": "Staking",
    "txitem.vout.receiver": "Receiver Address: ",
    "txitem.vout.amount": "Effective Amount: ",
    "txitem.vout.lockBlocks": "Lock Blocks: ",
    // address view
    "addressview.title": "Address Summary",
    "addressview.address": "Address",
    "addressview.amount": "Amount",
    "addressview.viewcoins": "View Coins",
    "addressview.spendable": "Spendable",
    "addressview.txn": "Transaction Count",
    "addressview.stakingfrom": "Staking From",
    "addressview.stakingto": "Staking To",
    "addressview.pointfrom": "Point From",
    "addressview.pointto": "Point To",
    "addressview.miningblock": "Mined Block of Week",
    "addressview.miningblock.percent": "Percent",
    "addressview.bindcount": "Bind Plotters",
    "addressview.capacity": "Estimate Capacity",
    "addressview.miningrequire": "Pledge Reuire",
    "addressview.txs": "Relate Transactions",
    // plotter list
    "plotterlist.title": "Plotters Summary",
    "plotterlist.address": "Bind Address",
    "plotterlist.total": "Total",
    "plotterlist.plotter": "Plotter",
    "plotterlist.bindTx": "Bind Transaction",
    // plotter view
    "plotterview.title": "Plotter Summary",
    "plotterview.plotter": "Plotter",
    "plotterview.capacity": "Estimate Capacity",
    "plotterview.miningblock": "Mined Block of Week",
    "plotterview.address": "Bind Address",
    "plotterview.txid": "Bind Transaction",
    "plotterview.bindheightlimit": "Change Bind Limit to",
    "plotterview.unbindheightlimit": "UnBind Limit to",
    "plotterview.notbind": "Not bind to any Address",
    "plotterview.blocks": "Blocks",
    "plotterview.blocks.height": "Height",
    "plotterview.blocks.generator": "Generator",
    // accounts
    "accounts.type.account": "Top Holders",
    "accounts.type.point": "Top Point Holders",
    "accounts.type.staking": "Top Staking Holders",
    "accounts.NO": "NO.",
    "accounts.address": "Address",
    "accounts.amount": "Amount",
    "accounts.summary.amount": "Amount",
    "accounts.summary.total": "Total",
    // coin list
    "coinlist.title": "Coins Summary",
    "coinlist.address": "Address",
    "coinlist.total": "Total",
    "coinlist.txid": "Transaction",
    "coinlist.amount": "Amount",
    "coinlist.type": "Type",
    "coinlist.type.Coinbase": "Coinbase",
    "coinlist.type.Pay": "Pay",
    "coinlist.type.BindPlotter": "Bind Plotter",
    "coinlist.type.Point": "Point",
    "coinlist.type.Staking": "Staking",
}