import React, { Fragment } from 'react';
import {
    Button,
    Card,
    List,
    Space,
    Tooltip,
} from 'antd';
import {
    ArrowRightOutlined,
    DownOutlined,
    InfoCircleOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Common from '../Common';
import { formatSize, formatTime } from '@explorer-utils/format';

import './TransactionItem.less'

const TransactionItem = ({ data, index = -1, compact = false }) => {
    const highlightVoutN = (() => {
        if (!window.location.hash.startsWith('#vout-')) {
            return -1;
        }
        return parseInt(window.location.hash.substring(6));
    })()

    const [fold, setFold] = React.useState(compact);

    const Title = () => (
        <div className="explorer-txitem-title">
            <Common.LinkLabel value={data.txid} type="transaction" compact={compact} />
            {index >= 0 && (
                <span className="explorer-txitem-NO">#{index + 1}</span>
            )}
        </div>
    );
    const InItem = ({ item }) => {
        if (item === "coinbase") {
            return (
                <div
                    key={`txin-${data.txid}-coinbase`}
                    className="explorer-txitem-txs-item"
                >
                    <FormattedMessage id="txitem.coinbase" />
                </div>
            )
        }

        return (
            <div
                key={`txin-${item.txid}_${item.vout}`}
                className="explorer-txitem-txs-item"
            >
                <Common.LinkLabel value={item.address} type="address" copyable={false} />
                <a className="explorer-in-tx explorer-transaction" href={`/blockchain/transaction/${item.txid}#vout-${item.vout}`}>#{item.vout}</a>
                <span className="explorer-amount">{item.amount} QTC</span>
            </div>
        )
    };
    const OutItem = ({ item, index, total, coinbase }) => {
        const Tips = ({ className = "" }) => {
            const MyTooltip = ({ title = "" }) => (
                <Tooltip className={className} placement="right" title={title}>
                    <InfoCircleOutlined />
                </Tooltip>
            )
            if (coinbase) {
                if (index === 0) {
                    return (<MyTooltip title={<FormattedMessage id="txitem.vout.MinedReward" />} />)
                }
            } else if (item.type === "BindPlotter") {
                return (<MyTooltip title={(
                    <div className="explorer-address-tips">
                        <div><FormattedMessage id="txitem.vout.BindPlotter" /></div>
                        <div><Common.LinkLabel value={item.payload.plotterId} type="plotter" /></div>
                    </div>
                )} />)
            } else if (item.type === "Point") {
                return (<MyTooltip title={(
                    <div className="explorer-address-tips">
                        <div><FormattedMessage id="txitem.vout.Point" /></div>
                        <div><FormattedMessage id="txitem.vout.receiver" /><Common.LinkLabel value={item.payload.receiver} type="address" compact={true} /></div>
                        <div><FormattedMessage id="txitem.vout.amount" /><span className="explorer-amount">{item.payload.amount} QTC</span></div>
                        <div><FormattedMessage id="txitem.vout.lockBlocks" />{item.payload.lockBlocks}</div>
                    </div>
                )} />)
            } else if (item.type === "Staking") {
                return (<MyTooltip title={(
                    <div className="explorer-address-tips">
                        <div><FormattedMessage id="txitem.vout.Staking" /></div>
                        <div><FormattedMessage id="txitem.vout.receiver" /><Common.LinkLabel value={item.payload.receiver} type="address" compact={true} /></div>
                        <div><FormattedMessage id="txitem.vout.amount" /><span className="explorer-amount">{item.payload.amount} QTC</span></div>
                        <div><FormattedMessage id="txitem.vout.lockBlocks" />{item.payload.lockBlocks}</div>
                    </div>
                )} />)
            }

            return (<Fragment />)
        }

        return (
            <div
                key={`txout-${item.txid}_${item.n}`}
                name={`vout-${index}`}
                className={`explorer-txitem-txs-item ${highlightVoutN === index ? 'highlight': ''}`}
            >
                <Common.LinkLabel value={item.address} type="address" copyable={false} />
                <Tips className="explorer-address-info" />
                <span className="explorer-amount">{item.amount} QTC</span>
            </div>
        )
    };
    const ItemsFolder = () => {
        if (data.vin.length < 5 && data.vout.length < 5) {
            return (<Fragment />);
        }

        return (
            <div className={`explorer-txitem-txs-folder ${fold ? "fold" : "unfold"}`}>
                {fold ? (
                    <Button icon={<DownOutlined />} shape="circle" onClick={() => setFold(false)} />
                ) : (
                    <Button icon={<UpOutlined />} shape="circle" onClick={() => setFold(true)} />
                )}
            </div>
        );
    };
    return (
        <Card
            className='explorer-txitem'
            key={`txitem-${data.txid}`}
            title={<Title />}
        >
            <div className="explorer-txitem-time explorer-box">
                <span className="explorer-box-title"><FormattedMessage id="txitem.time" /></span>
                <span className="explorer-box-content">{formatTime(data.time)}</span>
            </div>
            {data.from.length === 1 && data.to.length === 1 && (
                <Fragment>
                    <div className="explorer-txitem-from explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="txitem.from" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={data.from[0]} type="address" />
                        </span>
                    </div>
                    <div className="explorer-txitem-to explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="txitem.to" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={data.to[0]} type="address" />
                        </span>
                    </div>
                </Fragment>
            )}
            <div className="explorer-txitem-amount explorer-box">
                <span className="explorer-box-title"><FormattedMessage id="txitem.amount" /></span>
                <span className="explorer-box-content">{data.amount} QTC</span>
            </div>
            <div className="explorer-txitem-fee explorer-box">
                <span className="explorer-box-title"><FormattedMessage id="txitem.fee" /></span>
                <span className="explorer-box-content">{data.fee} QTC</span>
            </div>
            <div className="explorer-txitem-size explorer-box">
                <span className="explorer-box-title"><FormattedMessage id="txitem.size" /></span>
                <span className="explorer-box-content">{formatSize(data.size)}</span>
            </div>
            <div className="explorer-txitem-blockhash explorer-box">
                <span className="explorer-box-title"><FormattedMessage id="txitem.blockhash" /></span>
                <span className="explorer-box-content">
                    <Space>
                        <span>#{data.blockheight}</span>
                        <Common.LinkLabel value={data.blockhash} type="block" compact={true} />
                    </Space>
                </span>
            </div>
            <div className={`explorer-txitem-txs ${fold ? "fold" : ""}`}>
                <List
                    className="explorer-txitem-txs-vin"
                    dataSource={data.vin.length > 0 ? data.vin : ['coinbase']}
                    renderItem={(item) => <InItem item={item} />}
                />
                <ArrowRightOutlined className="explorer-txitem-txs-arrow" />
                <List
                    className="explorer-txitem-txs-vout"
                    dataSource={data.vout}
                    renderItem={(item, index) => <OutItem item={item} index={index} total={data.vout.length} coinbase={data.vin.length === 0} />}
                />
            </div>
            <ItemsFolder />
        </Card>
    )
};

export default TransactionItem;