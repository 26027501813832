import React, { Fragment } from 'react';
import {
    message,
    Skeleton,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TransactionItem from './TransactionItem';
import { doRequest } from '@explorer-utils/request';

import './TransactionView.less'

const TransactionView = withRouter(({ match }) => {
    const txid = match.params.txid;

    const [transaction, setTransaction] = React.useState(null);

    React.useEffect(() => {
        doRequest.get({
            url: `/api/blockchain/transaction/${txid}`,
        }).then(result => {
            setTransaction(result.data);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        });
    }, [txid]);

    return (
        <div className='explorer-txview'>
            {transaction ? (
                <TransactionItem data={transaction} compact={false} />
            ) : (
                <Skeleton loading={true} />
            )}
        </div>
    )
});

export default TransactionView;