import React from 'react';
import {
    message,
    Table,
    Pagination,
    Radio,
    Skeleton,
} from 'antd';
import { Common } from "@explorer-components";
import { FormattedMessage } from 'react-intl';
import { doRequest } from '@explorer-utils/request';

import './index.less';

const AccountsPage = () => {
    const [optionType, setOptionType] = React.useState('account');

    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const [accountSummaryLoading, setAccountSummaryLoading] = React.useState(false);
    const [accountSummary, setAccountSummary] = React.useState();

    React.useEffect(() => {
        setLoading(true);
        doRequest.get({
            url: `/api/blockchain/${optionType}`,
            data: {
                page: page - 1,
                count: countOfPage,
            }
        }).then(({ data }) => {
            setAccounts(data.data);
            setTotal(data.total);

            return doRequest.get({
                url: `/api/blockchain/${optionType}/summary`,
            })
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [optionType, page]);

    React.useEffect(() => {
        setAccountSummaryLoading(true);
        doRequest.get({
            url: `/api/blockchain/${optionType}/summary`,
        }).then(({ data }) => {
            setAccountSummary(data);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setAccountSummaryLoading(false));
    }, [optionType]);

    const columns = [
        {
            title: <FormattedMessage id="accounts.NO" />,
            dataIndex: 'address',
            key: 'address',
            render: (address, record, index) => {
                return <span>#{index + (page - 1) * countOfPage + 1}</span>
            },
        },
        {
            title: <FormattedMessage id="accounts.address" />,
            dataIndex: 'address',
            key: 'address',
            render: (address) => {
                return (<Common.LinkLabel value={address} type="address" copyable={false} />)
            },
        },
        {
            title: <FormattedMessage id="accounts.amount" />,
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => {
                return <span className="explorer-amount">{amount} QTC</span>
            },
        },
    ];

    return (
        <div className="explorer-accounts">
            <div className="explorer-accounts-options">
                <Radio.Group
                    className="explorer-accounts-options-type"
                    value={optionType}
                    onChange={(e) => { setOptionType(e.target.value); setPage(1); }}
                >
                    <Radio.Button value="account"><FormattedMessage id="accounts.type.account" /></Radio.Button>
                    <Radio.Button value="point"><FormattedMessage id="accounts.type.point" /></Radio.Button>
                    <Radio.Button value="staking"><FormattedMessage id="accounts.type.staking" /></Radio.Button>
                </Radio.Group>
            </div>

            <Skeleton loading={accountSummaryLoading}>
                <div className="explorer-accounts-summary">
                    <div className="explorer-accounts-amount explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="accounts.summary.amount" /></span>
                        <span className="explorer-box-content">
                            <span className='explorer-amount'>{accountSummary ? accountSummary.amount : ""} QTC</span>
                        </span>
                    </div>
                    <div className="explorer-accounts-total explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="accounts.summary.total" /></span>
                        <span className="explorer-box-content">{accountSummary ? accountSummary.accounts : ""} </span>
                    </div>
                </div>
            </Skeleton>

            <Table
                className="explorer-accounts-list"
                loading={loading}
                dataSource={accounts}
                columns={columns}
                pagination={false}
            />
            <Pagination
                className="explorer-pagination"
                current={page}
                total={total}
                pageSize={countOfPage}
                onChange={(v) => setPage(v)}
                showSizeChanger={false}
            />
        </div>
    )
};

export default AccountsPage;