import React from 'react';
import {
    message,
    Card,
    Pagination,
    Skeleton,
    Table,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Common from '../Common';
import { doRequest } from '@explorer-utils/request';

import './AddressPlotterList.less'

const AddressPlotterList = withRouter(({ match }) => {
    const address = match.params.address;

    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [plotters, setPlottres] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        setLoading(true)
        return doRequest.get({
            url: `/api/blockchain/address/${address}/plotter`,
            data: {
                page: page - 1,
                count: countOfPage,
            },
        }).then(result => {
            setPlottres(result.data.data);
            setTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [address, page]);

    const columns = [
        {
            title: <FormattedMessage id="plotterlist.plotter" />,
            dataIndex: 'plotter',
            key: 'plotter',
            render: (plotter) => {
                return (<Common.LinkLabel value={plotter} type="plotter" copyable={false} />)
            },
        },
        {
            title: <FormattedMessage id="plotterlist.bindTx" />,
            dataIndex: 'bind',
            key: 'bind',
            render: (bind) => {
                return (<Common.LinkLabel value={bind.txid} type="transaction" copyable={false} compact={true} />)
            },
        },
    ];

    const BaseTitle = () => (
        <div className="explorer-plotterlist-txs-title explorer-title">
            <FormattedMessage id="plotterlist.title" />
        </div>
    );
    return (
        <div className="explorer-plotterlist">
            <Skeleton loading={!plotters}>
                <Card
                    className="explorer-plotterlist-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-plotterlist-address explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="plotterlist.address" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={address} type="address" />
                        </span>
                    </div>
                    <div className="explorer-plotterlist-total explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="plotterlist.total" /></span>
                        <span className="explorer-box-content">{total}</span>
                    </div>
                </Card>
                <Table
                    className="explorer-plotterlist-table"
                    loading={loading}
                    dataSource={plotters}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    className="explorer-pagination"
                    current={page}
                    total={total}
                    pageSize={countOfPage}
                    onChange={(v) => setPage(v)}
                    showSizeChanger={false}
                />
            </Skeleton>
        </div>
    )
});

export default AddressPlotterList;