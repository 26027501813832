import React from 'react';
import {
    message,
    Card,
    Pagination,
    Skeleton,
    Table,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Common from '../Common';
import { doRequest } from '@explorer-utils/request';

import './AddressCoinList.less'

const AddressCoinList = withRouter(({ match }) => {
    const address = match.params.address;

    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [coins, setCoins] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        setLoading(true)
        return doRequest.get({
            url: `/api/blockchain/address/${address}/utxo`,
            data: {
                page: page - 1,
                count: countOfPage,
            },
        }).then(result => {
            setCoins(result.data.data);
            setTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [address, page]);

    const columns = [
        {
            title: <FormattedMessage id="coinlist.txid" />,
            dataIndex: 'txid',
            key: 'txid',
            render: (txid, record) => {
                return (<Common.LinkLabel value={`${txid}#vout-${record.n}`} type="transaction" copyable={false} compact={true} />)
            },
        },
        {
            title: <FormattedMessage id="coinlist.amount" />,
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => {
                return <span className="explorer-amount">{amount} QTC</span>
            },
        },
        {
            title: <FormattedMessage id="coinlist.type" />,
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                return <FormattedMessage id={`coinlist.type.${type}`} />
            },
        },
    ];

    const BaseTitle = () => (
        <div className="explorer-coinlist-txs-title explorer-title">
            <FormattedMessage id="coinlist.title" />
        </div>
    );
    return (
        <div className="explorer-coinlist">
            <Skeleton loading={!coins}>
                <Card
                    className="explorer-coinlist-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-coinlist-address explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="coinlist.address" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={address} type="address" />
                        </span>
                    </div>
                    <div className="explorer-coinlist-total explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="coinlist.total" /></span>
                        <span className="explorer-box-content">{total}</span>
                    </div>
                </Card>
                <Table
                    className="explorer-coinlist-table"
                    loading={loading}
                    dataSource={coins}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    className="explorer-pagination"
                    current={page}
                    total={total}
                    pageSize={countOfPage}
                    onChange={(v) => setPage(v)}
                    showSizeChanger={false}
                />
            </Skeleton>
        </div>
    )
});

export default AddressCoinList;