import AntENUS from 'antd/lib/locale-provider/en_US';
import AntZHCN from 'antd/lib/locale-provider/zh_CN';

import AppENUS from './en-US';
import AppZHCN from './zh-CN';

const supportLanguages = ['en-US', 'zh-CN'];
const mapLanguages = {
    'en-US': [AppENUS, AntENUS],
    'zh-CN': [AppZHCN, AntZHCN],
};

const getLocale = () => {
    let current = localStorage.getItem("lang");
    if (!current || !supportLanguages.indexOf(current) === -1) {
        for (let lang in navigator.languages) {
            if (supportLanguages.indexOf(lang) !== -1) {
                current = lang;
                break;
            }
        }
    }
    if (!current || !supportLanguages.indexOf(current) === -1) {
        current = supportLanguages[0];
    }

    let data = mapLanguages[current];
    return {
        lang: current,
        locale: current.split('-')[0],
        app: data[0],
        ant: data[1],
    };
}

const setLocale = (lang) => {
    if (!lang || !supportLanguages.indexOf(lang) === -1) {
        return;
    }
    localStorage.setItem("lang", lang);
    document.location.reload();
}

export { getLocale, setLocale }