import React, { Fragment } from 'react';
import {
    message,
    Card,
    Table,
    Pagination,
    Skeleton,
    Space,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Common from '../Common';
import { doRequest } from '@explorer-utils/request';
import { formatCapacity } from '@explorer-utils/format';

import './PlotterView.less'

const PlotterView = withRouter(({ match }) => {
    const plotterId = match.params.plotterId;

    const [loading, setLoading] = React.useState(false);
    const [plotter, setPlotter] = React.useState(null);

    const blockCountOfPage = 5;
    const [loadingBlock, setLoadingBlock] = React.useState(false);
    const [blocks, setBlocks] = React.useState(null);
    const [blockPage, setBlockPage] = React.useState(1);
    const [blockTotal, setBlockTotal] = React.useState(0);

    React.useEffect(() => {
        // reset block page
        setBlockPage(1);

        setLoading(true);
        doRequest.get({
            url: `/api/blockchain/plotter/${plotterId}`,
        }).then(result => {
            setPlotter(result.data);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [plotterId]);
    React.useEffect(() => {
        setLoadingBlock(true);
        doRequest.get({
            url: `/api/blockchain/plotter/${plotterId}/block`,
            data: {
                page: blockPage - 1,
                count: blockCountOfPage,
            }
        }).then(result => {
            setBlocks(result.data.data);
            setBlockTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoadingBlock(false));
    }, [plotterId, blockPage]);

    const blockColumns = [
        {
            title: <FormattedMessage id="plotterview.blocks.height" />,
            dataIndex: 'height',
            key: 'height',
            render: (height, record) => {
                return (<a href={`/blockchain/block/${record.hash}`}>{height}</a>)
            },
        },
        {
            title: <FormattedMessage id="plotterview.blocks.generator" />,
            dataIndex: 'generator',
            key: 'generator',
            render: (generator) => {
                return (<a href={`/blockchain/address/${generator}`}>{generator}</a>)
            },
        },
    ];

    const BaseTitle = () => (
        <div className="explorer-plotterview-base-title">
            <FormattedMessage id="plotterview.title" />
        </div>
    );
    const BlocksTitle = () => (
        <div className="explorer-plotterview-blocks-title explorer-title">
            <Space>
                <span><FormattedMessage id="plotterview.blocks" /></span>
                <span>({blockTotal})</span>
            </Space>
        </div>
    );
    return (
        <div className="explorer-plotterview">
            <Skeleton loading={loading || !plotter}>
                <Card
                    className="explorer-plotterview-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-plotterview-plotter explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="plotterview.plotter" /></span>
                        <span className="explorer-box-content">
                            <span className="explorer-plotter">{plotter ? plotter.plotter : ''}</span>
                        </span>
                    </div>
                    <div className="explorer-plotterview-capacity explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="plotterview.capacity" /></span>
                        <span className="explorer-box-content">{plotter ? (plotter.miningblock > 0 ? formatCapacity(plotter.capacity) : '0 TiB') : ''}</span>
                    </div>
                    <div className="explorer-plotterview-miningblock explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="plotterview.miningblock" /></span>
                        <span className="explorer-box-content">{plotter ? plotter.miningblock : ''}</span>
                    </div>
                    {plotter && plotter.bind ? (
                        <Fragment>
                            <div className="explorer-plotterview-address explorer-box">
                                <span className="explorer-box-title"><FormattedMessage id="plotterview.address" /></span>
                                <span className="explorer-box-content">
                                    <Common.LinkLabel value={plotter.bind.address} type="address" />
                                </span>
                            </div>
                            <div className="explorer-plotterview-txid explorer-box">
                                <span className="explorer-box-title"><FormattedMessage id="plotterview.txid" /></span>
                                <span className="explorer-box-content">
                                    <Common.LinkLabel value={plotter.bind.txid} type="transaction" compact={true} />
                                </span>
                            </div>
                            <div className="explorer-plotterview-unbindheightlimit explorer-box">
                                <span className="explorer-box-title"><FormattedMessage id="plotterview.unbindheightlimit" /></span>
                                <span className="explorer-box-content">{plotter.bind.unbindheightlimit}</span>
                            </div>
                            <div className="explorer-plotterview-bindheightlimit explorer-box">
                                <span className="explorer-box-title"><FormattedMessage id="plotterview.bindheightlimit" /></span>
                                <span className="explorer-box-content">{plotter.bind.unbindheightlimit}</span>
                            </div>
                        </Fragment>
                    ) : (
                        <span className="explorer-plotterview-notbind"><FormattedMessage id="plotterview.notbind" /></span>
                    )}
                </Card>
            </Skeleton>
            <Skeleton loading={loading || !blocks}>
                <Card
                    className="explorer-plotterview-txs"
                    title={<BlocksTitle />}
                >
                    <Table
                        dataSource={blocks}
                        loading={loadingBlock}
                        columns={blockColumns}
                        pagination={false}
                    />
                    <Pagination
                        className="explorer-pagination"
                        current={blockPage}
                        total={blockTotal}
                        pageSize={blockCountOfPage}
                        onChange={(v) => setBlockPage(v)}
                        showSizeChanger={false}
                    />
                </Card>
            </Skeleton>
        </div>
    )
});

export default PlotterView;