import React from 'react';
import { Spin } from 'antd';

const Global = () => {
    return (
        <Spin
            className="explorer-app-loading"
            size="large" />
    );
}

export default { Global };