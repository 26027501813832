import React from 'react';
import {
    message,
    Radio,
    Skeleton,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { doRequest } from '@explorer-utils/request';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import './CapacityChart.less'

const CapacityChart = ({ className = "" }) => {
    const [loading, setLoading] = React.useState(false);
    const [capacityData, setCapacityData] = React.useState(null);
    const [rangeType, setRangeType] = React.useState('week'); // week, month, quarter, year, all

    React.useEffect(() => {
        setLoading(true);
        doRequest.get({
            url: '/api/blockchain/capacity/',
            data: {
                begin: rangeType=="week"?0:rangeType=="month"? - 1*480*30:rangeType=="quarter"? - 1*480*120: - 1*480*365,
                end: 0,
            }
        }).then(result => {
            setCapacityData({
                items: result.data.data.map(item => [item.time * 1000, item.capacity / 1024]),
            });
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [rangeType]);

    return (
        <div className={`explorer-capacitychart ${className}`}>
            <div className="explorer-capacitychart-options">
                <Radio.Group
                    value={rangeType}
                    onChange={(e) => { setRangeType(e.target.value); }}
                >
                    <Radio.Button value="week"><FormattedMessage id="capacity.range.week" /></Radio.Button>
                    <Radio.Button value="month"><FormattedMessage id="capacity.range.month" /></Radio.Button>
                    <Radio.Button value="quarter"><FormattedMessage id="capacity.range.quarter" /></Radio.Button>
                    <Radio.Button value="year"><FormattedMessage id="capacity.range.year" /></Radio.Button>
                    <Radio.Button value="all"><FormattedMessage id="capacity.range.all" /></Radio.Button>
                </Radio.Group>
            </div>
            <Skeleton loading={loading || !capacityData}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        title: {
                            text: ''
                        },
                        xAxis: {
                            title: {
                                text: ''
                            },
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                day: '%Y-%m-%d',
                                minute: '%H:%M'
                            },
                            tickInterval: 5,
                        },
                        yAxis: {
                            title: {
                                text: 'PiB'
                            },
                            min: 0,
                        },
                        series: [{
                            type: 'area',
                            name: 'Capacity',
                            data: capacityData ? capacityData.items : [],
                            fillColor: {
                                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                                stops: [
                                    [0, 'rgba(124,181,236,0.8)'],
                                    [1, 'rgba(124,181,236,0.1)']
                                ]
                            },
                        }],
                        legend: {
                            enabled: false,
                        },
                    }}
                />
            </Skeleton>
        </div>
    )
};

export default CapacityChart;