import React, { Fragment } from 'react';
import {
    message,
    Pagination,
    Table,
} from 'antd';
import {
    LinkOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Common from '../Common';
import { formatTime } from '@explorer-utils/format';
import { doRequest } from '@explorer-utils/request';


import './BlockList.less'

const BlockList = ({ className = "", count = 10, pagination = false }) => {
    const [loading, setLoading] = React.useState(false);
    const [blocks, setBlocks] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const updateBlocks = () => {
        setLoading(true)
        return doRequest.get({
            url: '/api/blockchain/block',
            data: {
                page: page - 1,
                count: count,
            },
        }).then(result => {
            setBlocks(result.data.data);
            setTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    };

    React.useEffect(() => {
        updateBlocks();
    }, [page]);

    const columns = [
        {
            title: <FormattedMessage id="blocklist.height" />,
            dataIndex: 'height',
            key: 'height',
            render: (height, record) => {
                return (<a href={`/blockchain/block/${record.hash}`}>{height}</a>)
            },
        },
        {
            title: <FormattedMessage id="blocklist.time" />,
            dataIndex: 'time',
            key: 'time',
            render: (time) => {
                return formatTime(time);
            },
        },
        {
            title: <FormattedMessage id="blocklist.tx" />,
            dataIndex: 'tx',
            key: 'tx',
        },
        {
            title: <FormattedMessage id="blocklist.generator" />,
            dataIndex: 'generator',
            key: 'generator',
            render: (generator, record) => {
                if (!record.tag || !record.tag.title) {
                    return (<Common.LinkLabel value={generator} type="address" copyable={false} />)
                }

                return (
                    <Fragment>
                        <Common.LinkLabel value={generator} type="address" copyable={false} />
                        (
                        <a href={record.tag.link} target="_blank">
                            <LinkOutlined />
                            {record.tag.title}
                        </a>
                        )
                    </Fragment>
                )
            },
        },
    ];
    const TablePagination = () => {
        if (!pagination || !blocks) {
            return false;
        }

        return (
            <Pagination
                className="explorer-pagination"
                current={page}
                total={total}
                pageSize={count}
                onChange={(v) => setPage(v)}
                showSizeChanger={false}
            />
        )
    }
    return (
        <Fragment>
            <Table
                className={`explorer-blocklist ${className}`}
                loading={loading}
                dataSource={blocks}
                columns={columns}
                pagination={false}
            />
            <TablePagination />
        </Fragment>
    )
};

export default BlockList;