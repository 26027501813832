import AddressCoinList from './AddressCoinList'
import AddressPlotterList from './AddressPlotterList'
import AddressView from './AddressView'
import BlockList from './BlockList'
import BlockView from './BlockView'
import CapacityChart from './CapacityChart'
import PlotterView from './PlotterView'
import TransactionItem from './TransactionItem'
import TransactionView from './TransactionView'

export default { AddressCoinList, AddressView, AddressPlotterList, BlockList, BlockView, CapacityChart, PlotterView, TransactionItem, TransactionView }