import React from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';

const DurtationLabel = ({ className = "", durtation = 0, visibility = {} }) => {
    const days = parseInt(durtation / 86400);
    const hoursOfDay = parseInt((durtation % 86400) / 3600);
    const minutesOfHour = parseInt((durtation % 3600) / 60);
    const secondsOfMinute = parseInt(durtation % 60);

    return (
        <Space className={className} size="small">
            {visibility && visibility.day !== false && (
                <React.Fragment>
                    {days}
                    <FormattedMessage id="components.timer.days" />
                </React.Fragment>
            )}
            {visibility && visibility.hour !== false && (
                <React.Fragment>
                    {hoursOfDay}
                    <FormattedMessage id="components.timer.hours" />
                </React.Fragment>
            )}
            {visibility && visibility.minute !== false && (
                <React.Fragment>
                    {minutesOfHour}
                    <FormattedMessage id="components.timer.minutes" />
                </React.Fragment>
            )}
            {visibility && visibility.second !== false && (
                <React.Fragment>
                    {secondsOfMinute}
                    <FormattedMessage id="components.timer.seconds" />
                </React.Fragment>
            )}
        </Space>
    )
};

export default DurtationLabel;