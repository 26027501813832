import React from 'react';
import { Image } from 'antd';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
    return (
        <div className="explorer-footer">
            <div className="explorer-footer-summary">
                <Image className="explorer-logo" src="/static/images/logo_pc.3c794f8d.png" preview={false} />
                <div className="explorer-footer-links">
                    <a href="https://qitchain.net" target="_blank"><FormattedMessage id="footer.link.org" /></a>
                    <a href="https://github.com/qitchain/qitcoin" target="_blank"><FormattedMessage id="footer.wallet.sourcecode" /></a>
                    <a href="https://github.com/qitchain/qitcoin/releases" target="_blank"><FormattedMessage id="footer.wallet.download" /></a>
                    |
                    <a href="https://coinmarketcap.com/currencies/qitchain-network/" target="_blank"><FormattedMessage id="footer.link.coinmarketcap" /></a>
                    <a href="https://www.feixiaohao.co/currencies/qitcoin/" target="_blank"><FormattedMessage id="footer.link.feixiaohao" /></a>
                    <a href="https://www.coinw.com/front/proTrading?symbol=740" target="_blank"><FormattedMessage id="footer.link.coinw" /></a>
                    <a href="https://www.coincarp.com/currencies/qitchain-network/" target="_blank"><FormattedMessage id="footer.link.coincarp" /></a>
                    <a href="https://www.balletcrypto.com" target="_blank"><FormattedMessage id="footer.link.balletcrypto" /></a>
                    <a href="https://finance.yahoo.com/quote/QTC-USD?p=QTC-USD&guccounter=1" target="_blank">Yahoo</a>
                    <a href="https://www.livecoinwatch.com/price/Qitcoin-QTC" target="_blank">LiveCoinWatch</a>
                    <a href="https://www.gate.io/cn/trade/QTC_USDT" target="_blank">Gate.io</a>
                    <a href="https://www.coingecko.com/en/coins/qitchain-network" target="_blank">CoinGecko</a>
                    <a href="https://crypto.com/price/qitchain-network" target="_blank">Crypto.com</a>
                </div>
            </div>
            <div className="explorer-footer-copyright">
                <FormattedMessage id="app.copyright" />
            </div>
        </div>

    );
};

export default Footer;