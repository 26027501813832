import axios from 'axios'

const CANCEL_REQUEST_MESSAGE = 'cancel request'

const { CancelToken } = axios
window.cancelRequest = new Map()

function request(options) {
    let { url, method = 'GET', headers = [], data } = options
    let axiosOptions = {
        url: url,
        method: method,
        headers: headers,
        data: data,
        cancelToken: new CancelToken(cancel => {
            window.cancelRequest.set(Symbol(Date.now()), {
                pathname: window.location.pathname,
                cancel,
            })
        })
    }

    return axios(axiosOptions)
        .then(response => {
            const { statusText, status, data } = response
            return Promise.resolve({
                success: true,
                message: statusText,
                statusCode: status,
                data: data,
            })
        })
        .catch(error => {
            const { response, message } = error

            if (String(message) === CANCEL_REQUEST_MESSAGE) {
                return {
                    success: false,
                }
            }

            let statusCode
            let responseCode
            let responseError
            if (response && response instanceof Object) {
                const { data, statusText } = response
                statusCode = response.status
                responseCode = data.code || 1
                responseError = data.error || statusText
            } else {
                statusCode = 600
                responseCode = 1
                responseError = message || 'Network Error'
            }

            /* eslint-disable */
            return Promise.reject({
                success: false,
                statusCode: statusCode,
                code: responseCode,
                error: responseError,
            })
        })
}

const doRequest = {
    get: function ({ url, data }) {
        var query = "";
        if (data) {
            for (const [name, value] of Object.entries(data)) {
                if (query !== "") {
                    query += "&"
                }
                query += encodeURIComponent(name) + "=" + encodeURIComponent(value)
            }
        }
        if (query !== "" && url.indexOf("?") === -1) {
            query = "?" + query
        }

        return request({
            url: url + query,
            method: "GET",
        })
    },
    post: function ({ url, data }) {
        return request({
            url: url,
            method: "POST",
            data: data,
        })
    },
    put: function ({ url, data }) {
        return request({
            url: url,
            method: "PUT",
            data: data,
        })
    },
    delete: function ({ url }) {
        return request({
            url: url,
            method: "DELETE",
        })
    },
}

export { request, doRequest }