import React from 'react';
import { Result, Button } from 'antd';
import { FormattedMessage } from 'react-intl';

const NotFound = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle={<FormattedMessage id="error.message404" />}
            extra={<Button type="primary" onClick={() => document.location.href = "/"}><FormattedMessage id="error.gotohome" /></Button>}
        />
    );
};

export default NotFound;